<template>
    <div>
        <el-form
            ref="formData"
            v-loading="$wait.is('modal.loading.*')"
            element-loading-spinner="el-custom-spinner"
            :model="formData"
            label-position="top"
        >
            <div class="px-8 grid grid-cols-12 gap-4">
                <div class="col-span-6">
                    <SelectedDate :selectedDateProp="startDate" />
                </div>

                <div class="col-span-4">
                    <TimePickers
                        ref="timePickersForm"
                        :startTime="startTime"
                        :endTime="endTime"
                        @startTimeChanged="startTime = $event"
                        @endTimeChanged="endTime = $event"
                    />
                </div>

                <div class="col-span-1">
                    <ColorSelect :selectedColorProp="selectedColor" @change="selectedColor = $event" />
                </div>
            </div>

            <div class="px-8 grid grid-cols-12 gap-4">
                <div class="col-span-6">
                    <div class="flex">
                        <div class="flex-1">
                            <EmployeesSelect
                                v-if="loaded"
                                :selectedEmployeesProp="formData.selectedEmployees"
                                @change="formData.selectedEmployees = $event"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-span-6">
                    <ServicesSelect
                        v-if="loaded"
                        :selectedServicesProp="formData.selectedServices"
                        @change="formData.selectedServices = $event"
                    />
                </div>
            </div>

            <div class="px-8 mb-14 mt-6">
                <el-tabs>
                    <el-tab-pane>
                        <span slot="label" class="flex items-center font-normal">
                            <span class="w-4 h-4 mr-1 rounded" :class="formData.note ? 'bg-green-400' : 'bg-gray-300'" />
                            {{ $t('calendar.note_for_employee') }}
                        </span>
                        <NoteForEmployee v-if="loaded" :noteForEmployeeProp="formData.note" @onChange="onChangeNoteForEmployee" />
                    </el-tab-pane>
                    <el-tab-pane label="Config">
                        <span slot="label" class="flex items-center font-normal">
                            <span class="flex w-4 h-4 mr-1 rounded" :class="formData.client_note_private ? 'bg-green-400' : 'bg-gray-300'" />
                            {{ $t('calendar.private_note_from_client_profile') }}
                        </span>
                        <PrivateNote :clientNotePrivateProp="formData.client_note_private" />
                    </el-tab-pane>
                    <el-tab-pane label="Role">
                        <span slot="label" class="flex items-center font-normal">
                            <span class="flex w-4 h-4 mr-1 rounded" :class="formData.client_note_public ? 'bg-green-400' : 'bg-gray-300'" />
                            {{ $t('calendar.public_note_from_client_profile') }}
                        </span>
                        <PublicNote :clientNotePublicProp="formData.client_note_public" />
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-form>

        <Footer
            :recursive="recursive"
            :cancelled="cancelled"
            :eventsConfirmation="eventsConfirmation"
            :confirmation="confirmation"
            @deleteBtn="deleteEvents('single')"
            @deleteEvents="deleteEvents"
            @restore="cancellationToggle(0)"
            @unbook="cancellationToggle(1)"
            @confirm="confirm(true)"
            @undoConfirm="confirm(false)"
            @edit="editEvents('single')"
            @editEvents="editEvents"
            @closeModal="closeModal"
        />
    </div>
</template>
<script>
import includes from 'lodash/includes';

export default {
    components: {
        SelectedDate:    () => import(/* webpackChunkName: "ModalEditEvent/SelectedDate" */ './components/SelectedDate'),
        TimePickers:     () => import(/* webpackChunkName: "ModalEditEvent/TimePickers" */ './components/TimePickers'),
        ColorSelect:     () => import(/* webpackChunkName: "ModalEditEvent/ColorSelect" */ './components/ColorSelect'),
        // SmartSuggestions: () => import(/* webpackChunkName: "ModalEditEvent/SmartSuggestions" */ './components/SmartSuggestionView'),
        PrivateNote:     () => import(/* webpackChunkName: "ModalEditEvent/PrivateNote" */ './components/PrivateNote'),
        PublicNote:      () => import(/* webpackChunkName: "ModalEditEvent/PublicNote" */ './components/PublicNote'),
        NoteForEmployee: () => import(/* webpackChunkName: "ModalEditEvent/NoteForEmployee" */ './components/NoteForEmployee'),
        EmployeesSelect: () => import(/* webpackChunkName: "ModalEditEvent/EmployeesSelect" */ './components/EmployeesSelect'),
        ServicesSelect:  () => import(/* webpackChunkName: "ModalEditEvent/ServicesSelect" */ './components/ServicesSelect'),
        Footer:          () => import(/* webpackChunkName: "ModalEditEvent/Footer" */ './components/Footer'),
    },

    props: {
        modalVisible: {
            type: Boolean,
        },
        eventUuid: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            startTime:                     '07:00',
            endTime:                       '08:00',
            confirmation:                  0,
            servicesWithCategoriesData:    [],
            servicesWithoutCategoriesData: [],
            startDate:                     this.$dayjs(),
            recursive:                     0,
            cancelled:                     0,
            selectedCalendarData:          {},
            smartSuggestionsView:          false,
            selectedColor:                 '#20a0ff',
            modifiedServicesUuid:          [],
            modifiedEmployeesUuid:         [],
            loaded:                        false,
            formData:                      {
                selectedServices:  [],
                selectedEmployees: [],
                note:              '',
                client_note:       '',
            },
        };
    },

    computed: {
        employees() {
            return this.$store.state.employeesActiveDropdown;
        },

        servicesWithCategories() {
            return this.servicesData.forEach(category => category !== '');
        },

        eventsConfirmation() {
            return this.$store.state.eventConfirmation;
        },
    },

    watch: {
        modalVisible() {
            if (!this.modalVisible) return;
            this.startLoaders();
            this.getEventDetails();
        },
    },

    created() {
        this.startLoaders();
        if (this.$store.state.calendar.eventToEditUuid) {
            this.getEventDetails();
        }
    },

    methods: {
        startLoaders() {
            this.$wait.start('modal.loading.services');
        },

        async getEventDetails() {
            this.loaded = false;

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${this.eventUuid ? this.eventUuid : this.$store.state.calendar.eventToEditUuid}`);

            this.startDate = this.$dayjs(data.start_date);
            this.startTime = this.startDate.format('HH:mm');
            this.endTime = this.$dayjs(data.end_time).format('HH:mm');
            this.formData.note = data.note;
            this.formData.client_note_private = data.client_note_private;
            this.formData.client_note_public = data.client_note_public;
            this.selectedColor = data.color;
            this.recursive = data.recursive;
            this.cancelled = data.cancelled;
            this.confirmation = data.confirmation;
            this.modifiedServicesUuid = data.services_uuid;
            this.modifiedEmployeesUuid = data.employees_uuid;
            this.formData.selectedServices = data.services_uuid;
            this.formData.selectedEmployees = data.employees_uuid;

            this.$wait.end('modal.loading.services');

            this.$emit('updated', {
                clientUuid: data.client_uuid,
                modalTitle: `${data.client_name} ${data.client_surname}, ${data.street}, ${data.city}`,
            });

            this.loaded = true;
        },

        async editEvents(command) {
            await new Promise(resolve => this.$refs.timePickersForm.$refs.formData.validate(valid => valid && resolve()));
            await new Promise(resolve => this.$refs.formData.validate(valid => valid && resolve()));
            this.$wait.start('updating');

            const servicesToAdd = this.formData.selectedServices.filter(el => !this.modifiedServicesUuid.includes(el));
            const servicesToDelete = this.modifiedServicesUuid.filter(el => !this.formData.selectedServices.includes(el));
            const employeesToAdd = this.formData.selectedEmployees.filter(el => !this.modifiedEmployeesUuid.includes(el));
            const employeesToDelete = this.modifiedEmployeesUuid.filter(el => !this.formData.selectedEmployees.includes(el));

            // @Filip
            const employees = this.employees.filter(employee => {
                if (includes(employeesToAdd, employee.uuid)) {
                    return employee;
                }
            });

            const startTime = this.$dayjs(`${this.$dayjs(this.startDate).format('DD.MM.YYYY')} ${this.startTime}`, 'DD.MM.YYYY HH:mm');
            const endTime = this.$dayjs(`${this.$dayjs(this.startDate).format('DD.MM.YYYY')} ${this.endTime}`, 'DD.MM.YYYY HH:mm');
            const duration = endTime.diff(startTime, 'minute');

            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${this.eventUuid ? this.eventUuid : this.$store.state.calendar.eventToEditUuid}`, {
                type:                     command,
                color:                    this.selectedColor,
                start_event_at:           startTime.unix(),
                duration,
                note:                     this.formData.note,
                services_uuid_to_add:     servicesToAdd,
                services_uuid_to_delete:  servicesToDelete,
                employees_uuid_to_add:    employees,
                employees_uuid_to_delete: employeesToDelete,
            });

            this.$wait.end('updating');
            this.$notify.success({ title: this.$t('common.success') });
            this.$store.commit('calendar/refreshCalendarEvents');
            this.closeModal();
        },

        async deleteEvents(command) {
            const response = await this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
            }).catch(() => {});

            if (response !== 'confirm') return;

            this.$wait.start('deleting');

            await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${this.eventUuid ? this.eventUuid : this.$store.state.calendar.eventToEditUuid}`, {
                params: {
                    type: command,
                },
            });

            this.$notify.success({ title: this.$t('common.success') });

            this.$wait.end('deleting');

            this.$emit('refresh');
                this.$store.commit('calendar/refreshCalendarEvents');
            this.closeModal();
        },

        async confirm(status) {
            this.$wait.start('confirming');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/toggle_confirmation/${this.eventUuid ? this.eventUuid : this.$store.state.calendar.eventToEditUuid}`, {
                status: status ? 0 : 1,
            });
            this.$notify.success({ title: this.$t('common.success') });
            this.$wait.end('confirming');
            this.closeModal();
            this.$emit('refresh');
                this.$store.commit('calendar/refreshCalendarEvents');
        },

        async cancellationToggle(state) {
            this.$wait.start('updating');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/toggle_cancellation/${this.eventUuid ? this.eventUuid : this.$store.state.calendar.eventToEditUuid}`, {
                state,
            });
            this.$wait.end('updating');
            this.$notify.success({ title: this.$t('common.success') });
            this.$emit('refresh');
                this.$store.commit('calendar/refreshCalendarEvents');
            this.closeModal();
        },

        onChangeNoteForEmployee(value) {
            this.formData.note = value;
        },

        closeModal() {
            this.formData.note = '';
            this.formData.selectedEmployees = [];
            this.formData.selectedServices = [];
            this.$store.commit('calendar/closeModalEditNewEvent');
            this.$refs.formData.resetFields();
        },
    },
};
</script>
<style>
.dialog-edit-event .dialog-footer {
    display: flex;
    justify-content: space-between;
}
.dialog-edit-event .el-select {
    width: 100%;
}
.dialog-edit-event .el-date-editor.el-input, .dialog-edit-event .el-date-editor.el-input__inner {
    width: initial !important;
}

.el-textarea__inner {
    word-break: break-word;
}

.btn-replacement {
    padding: 0;
}
</style>
